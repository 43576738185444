import { gql } from "@apollo/client";

export const GET_CONFIG_QUERY = gql`
  query GetConfig {
    config {
      country
      currency
      features
      brandAvailableFulfilmentTypes
      scripts
      apps
      uiDefinitions {
        id
        type
        definition
      }
      accountId
      pointProgram {
        colors
        currencyNames
        currencyIconUrl
        bannerImageUrl
        label
        isCustomCurrency
        pointProgramHash
        userPortalUrl
        currencyValueInCents
      }
    }
    referral {
      label
      description
      shortDescription
      sidebarLabel
    }
    brands {
      id
      label
      identifier
      logoUrl
      config
    }
    currentStorefront {
      id
      label
      identifier
      logoUrl
      config
    }
  }
`;
